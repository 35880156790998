/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_content_delivery_bucket": "clientite-20191028070753-hostingbucket-jamestest",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://clientite-20191028070753-hostingbucket-jamestest.s3-website-eu-west-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "eu-west-1:51f68548-e770-4d03-a1f8-b1d6a1c58cbc",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_UdmNghS8r",
    "aws_user_pools_web_client_id": "6trmr977mtjm5qrc9v1vih47d8",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    }
};


export default awsmobile;
